$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$blue: #195ef7 !default;
$yellow: #ffd84b !default;
$green: #00dea6 !default;
$pink: #ff8bc3 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$body-bg: $white !default;
$body-color: $gray-900 !default;
$font-family-sans-serif: "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: "Roboto", Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
