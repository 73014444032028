header {
  height: 90vh;
  background-color: $blue;
  background-image: url("/img/beach-blue-car-combi-386025.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p.question {
    font-size: 3rem;
    color: $white;
    font-family: Roboto;
    text-shadow: 1px 1px 4px $black;
  }

  @media (max-width: 768px) {
    p.question {
      font-size: 3rem;
      color: $white;
      font-family: Roboto;
      text-shadow: 1px 1px 4px $black;
      text-align: center;
    }
  }

  .get-it-done {
    background-image: url("/img/get-it-done.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 40%;
    height: 10rem;
  }

  @media (max-width: 768px) {
    .get-it-done {
      background-image: url("/img/get-it-done.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 70%;
      height: 10rem;
    }
  }

  transition-property: opacity;
  transition-duration: 700ms;
  transition-timing-function: ease;
  opacity: 1;
  &.transitioning {
    opacity: 0;
  }
  &.car {
    background-image: url("/img/beach-blue-car-combi-386025.jpg");
  }
  &.concert {
    background-image: url("/img/people_at_concert_11_b4myW.jpg");
  }
  &.southeast {
    background-image: url("/img/aerial_asia_beach_bi_MI9ev.jpg");
  }
  &.vacations {
    background-image: url("/img/low_angle_photograph_inNmW.jpg");
  }
}

main {
  min-height: 100vh;
  background-color: $blue;
  color: $white;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "text phone";

  @media (max-width: 768px) {
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    grid-template-areas:
      "phone"
      "text";
  }
  .text {
    grid-area: text;
    padding-right: 3em;
    padding-left: 3em;
    padding-bottom: 3em;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  .phone {
    grid-area: phone;
    // background-image: url(/img/splash-android.png);
    // background-position: center center;
    // background-size: contain;
    // background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem;
    div.phone-preview {
      img {
        height: 75vh;
      }
      padding-bottom: 3rem;
    }
    div {
      flex-grow: 1;
      flex-basis: 0;
      text-align: center;
    }
  }
}

section.goal-simulator {
  min-height: 100vh;

  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: space-evenly;

  gap: 2rem;
  padding: 2rem;

  a {
    font-size: 1.5em;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  @media (min-width: 768px) {
    .firstColumn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: flex-start;
      width: 30%;
    }

    .secondColumn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: flex-start;
      width: 40%;
    }

    .firstStep {
      width: 100%;
    }
    .secondStep {
      width: 100%;
    }
  }

  &.step-1 {
    .firstStep {
      display: flex;
      flex-direction: column;

      .button-next-1 {
        opacity: 1;
        align-self: center;
        margin-top: auto;

        .btn {
          border-radius: 5px;
          width: auto !important;
          align-self: center;
          text-align: center !important;
        }
      }
    }
    .secondStep {
      display: flex;
      flex-direction: column;
      opacity: 0;
    }
    .thirdStep {
      display: flex;
      flex-direction: column;
      opacity: 0;
    }
  }
  &.step-2 {
    .secondStep {
      display: flex;
      flex-direction: column;
      opacity: 1;

      .button-next-2 {
        opacity: 1;
        align-self: center;
        margin-top: auto;

        .btn {
          border-radius: 5px;
          width: auto !important;
          align-self: center;
          text-align: center !important;
        }
      }

      .button-next-2-disable {
        opacity: 0;
        align-self: center;
        margin-top: auto;

        .btn {
          border-radius: 5px;
          width: auto !important;
          align-self: center;
          text-align: center !important;
        }
      }
    }
    .thirdStep {
      display: flex;
      flex-direction: column;
      opacity: 0;
    }
  }
  &.step-3 {
    .thirdStep {
      display: flex;
      flex-direction: column;

      .button-next-3 {
        opacity: 1;
        align-self: center;
        margin-top: auto;

        .btn {
          border-radius: 5px;
          width: 200px !important;
          align-self: center;
          text-align: center !important;
        }
      }

      .button-next-3-disable {
        opacity: 0;
        align-self: center;
        margin-top: auto;

        .btn {
          border-radius: 5px;
          width: auto !important;
          align-self: center;
          text-align: center !important;
        }
      }
    }
  }
  .step {
    transition-property: opacity;
    transition-duration: 700ms;
    transition-timing-function: ease;
  }
  .firstStep {
    grid-area: first-step;
  }
  .secondStep {
    grid-area: second-step;
  }
  .thirdStep {
    grid-area: third-step;

    margin-top: 2em;
  }

  .btn {
    border-radius: 50px;
    align-self: center;
    width: 50px;
    height: 50px;
    text-align: center !important;
  }

  .goal-total {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 2em;
  }
}

.shadow {
  box-shadow: 0px 0rem 0.15rem rgba(0, 0, 0, 0.2) !important;
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
}

footer {
  min-height: 50vh;
  background-color: $black;

  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "footer-black footer-blue";

  @media (max-width: 768px) {
    min-height: 50vh;
    background-color: $black;

    display: flex;
    flex-direction: column;
  }

  .footer-left {
    grid-area: footer-black;
    //background-color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;

    .done-img {
      max-width: 30%;
    }

    @media (max-width: 768px) {
      .done-img {
        max-width: 40%;
      }
    }

    .text-below-done {
      margin-top: 1rem;
      text-align: center;
      color: $white;
    }
  }
  .footer-right {
    grid-area: footer-blue;
    //background-color: $blue;
    color: $white;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;

    .licences {
      display: flex;
      div {
        height: 1rem;
        width: 1rem;
        margin: 0.25rem;
      }
      margin-bottom: 1em;
    }

    .bi-img {
      max-width: 40% !important;
      margin-bottom: 1.5em;
    }

    @media (max-width: 768px) {
      .bi-img {
        max-width: 70% !important;
      }
    }

    .text-below-done {
      margin-top: 1rem;
      text-align: center;
      color: $white;
    }
  }
}

.text-field {
  display: flex;
  flex-direction: column;
}

.dialog-container {
  margin: 1em;
}

.goal-dialog-title {
  background-color: $blue;
}

.goal-dialog-title-content {
  color: white;
  display: flex !important;
  flex-direction: column !important;
}

.goal-dialog-content {
  background-color: $blue;
  align-content: center;
}

.goal-dialog-content-text {
  color: white !important;
}

.goal-dialog-input {
  border-radius: 5px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 1em;
  align-self: center;
}

.goal-dialog-next {
  margin-top: 2em !important;
}

.goal-dialog-input-text-error {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $pink;
}

.goal-dialog-input-text-correct {
  display: none;
}

.goal-dialog-resend-email {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $pink;
}

.goal-dialog-resend-email-correct {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $green;
}

.MuiDialog-paperWidthSm {
  max-width: 400px !important;
}

.MuiButton-outlinedPrimary {
  color: white !important;
  border-color: white !important;
  width: 50% !important;
  align-self: center;
  margin-bottom: 2em !important;
  box-shadow: 0px 2px 4px $gray-600;
}

.MuiButton-outlinedPrimary-white {
  color: $blue !important;
  border-color: $white !important;
  width: 50% !important;
  align-self: center;
  margin-bottom: 2em !important;
  background-color: $white !important;
  box-shadow: 0px 2px 4px $gray-600;
}

.done-image {
  max-width: 50% !important;
  align-self: center !important;
  margin-bottom: 2em;
  margin-top: 2em;
}

.edit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .btn {
    height: 40px !important;
    width: 40px !important;
    margin-left: 1.5rem !important;
    border-radius: 5px !important;
  }
}

.sliderContainer {
  margin: 0em;
}

.PrivateValueLabel-circle-4 {
  width: 100px !important;
  transform: none !important;
  border-radius: 0% 0% 0% 0% !important;
  background-color: transparent !important;
}

.PrivateValueLabel-label-5 {
  color: $blue !important;
  transform: none !important;
}

.PrivateValueLabel-offset-3 {
  top: 2rem !important;
  font-size: 1em !important;
}

.jss5 {
  color: $blue !important;
  transform: none !important;
}

.jss4 {
  width: 100px !important;
  transform: none !important;
  border-radius: 0% 0% 0% 0% !important;
  background-color: transparent !important;
}

.jss3 {
  top: 30px !important;
  font-size: 1em !important;
}

.MuiSlider-valueLabel {
  left: auto !important;
}

.MuiSlider-root {
  color: $blue !important;
  width: 90% !important;
  margin: 1em;
}

.btn-init {
  margin-top: 2em;
  font-weight: bold;
  color: $white;
  width: 200px !important;
}

.MuiPickersToolbar-toolbar {
  background-color: $blue !important;
}
.MuiPickersDay-daySelected:hover {
  background-color: $pink !important;
}

.MuiPickersDay-daySelected {
  background-color: $pink !important;
}

.MuiButton-textPrimary {
  color: $blue !important;
}

.MuiSvgIcon-root {
  fill: $blue !important;
}

.btn {
  .remove-icon {
    fill: $blue !important;
  }
  .add-icon {
    fill: $blue !important;
  }
}

.btn:hover {
  .remove-icon {
    fill: $white !important;
  }
  .add-icon {
    fill: $white !important;
  }
}

.display-4 {
  font-weight: 800 !important;
  color: $blue !important;
}

.bg-blue {
  background-color: $blue;
}

.bg-pink {
  background-color: $pink;
}
.bg-black {
  background-color: $black;
}
.bg-yellow {
  background-color: $yellow;
}
.bg-green {
  background-color: $green;
}

.selected-goal-img {
  margin-top: 1.5em;
  max-width: 100%;
}
